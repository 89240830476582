import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("工具")]), _c("a-form-model", {
    ref: "toolForm",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      selfUpdate: true,
      model: _vm.info,
      layout: "horizontal",
      labelCol: {
        style: "min-width: 140px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "工具标题",
      prop: "name",
      rules: {
        required: true,
        message: "请输入工具标题"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      maxLength: 200,
      placeholder: ""
    },
    model: {
      value: _vm.info.name,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "name", $$v);
      },
      expression: "info.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "状态",
      prop: "status",
      rules: {
        required: true,
        message: "请选择状态"
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isView
    },
    model: {
      value: _vm.info.status,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "status", $$v);
      },
      expression: "info.status"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("待发布")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("已发布")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "工具分类",
      prop: "categories",
      rules: {
        required: true,
        validator: _vm.validateCategories
      }
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.isView,
      allowClear: "",
      mode: "multiple",
      placeholder: "选择分类",
      defaultValue: []
    },
    model: {
      value: _vm.info.categories,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "categories", $$v);
      },
      expression: "info.categories"
    }
  }, _vm._l(_vm.categoriesOptions, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "小卡片介绍",
      prop: "cardintroduce",
      rules: {
        required: true,
        message: "请输入小卡片介绍"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      maxLength: 200,
      placeholder: ""
    },
    model: {
      value: _vm.info.cardintroduce,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "cardintroduce", $$v);
      },
      expression: "info.cardintroduce"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "详情介绍",
      prop: "detailsintroduce",
      rules: {
        required: true,
        message: "请输入详情介绍"
      }
    }
  }, [!_vm.isView ? _c("a-textarea", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      autocomplete: "off",
      size: "large",
      rows: "8",
      maxLength: 5000,
      placeholder: ""
    },
    model: {
      value: _vm.info.detailsintroduce,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "detailsintroduce", $$v);
      },
      expression: "info.detailsintroduce"
    }
  }) : _c("div", {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      innerHTML: _vm._s(_vm.info.detailsintroduce)
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "ICON",
      prop: "icon",
      rules: {
        required: true,
        message: "请上传产品icon"
      }
    }
  }, [_c("UploadImg", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      showTips: false,
      listType: "picture-card",
      max: 1,
      imgProportion: [1280, 400],
      memorySize: "10240kb",
      disabled: _vm.isView,
      force: false,
      equalSides: false,
      from: "dctool"
    },
    on: {
      previewImg: _vm.previewImg,
      change: function change($event) {
        return _vm.reValidateField("icon");
      }
    },
    model: {
      value: _vm.info.icon,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "icon", $$v);
      },
      expression: "info.icon"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "产品截图",
      prop: "productscreenshots",
      rules: {
        required: false,
        message: "请上传产品截图"
      }
    }
  }, [_vm._l(_vm.info.productscreenshots, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "pro-img_list"
    }, [_c("UploadImg", {
      staticStyle: {
        width: "200px"
      },
      attrs: {
        showTips: false,
        listType: "picture-card",
        max: 1,
        imgProportion: [1280, 400],
        memorySize: "10240kb",
        disabled: _vm.isView,
        force: false,
        equalSides: false,
        from: "dctool"
      },
      on: {
        previewImg: _vm.previewImg,
        change: function change($event) {
          return _vm.reValidateField("productscreenshots");
        }
      },
      model: {
        value: _vm.info.productscreenshots[key],
        callback: function callback($$v) {
          _vm.$set(_vm.info.productscreenshots, key, $$v);
        },
        expression: "info.productscreenshots[key]"
      }
    }), !_vm.isView ? _c("span", {
      staticClass: "nft-list__icon",
      on: {
        click: function click($event) {
          return _vm.handleImg("del", key);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()], 1);
  }), _c("a-button", {
    attrs: {
      disabled: _vm.isView
    },
    on: {
      click: function click($event) {
        return _vm.handleImg("add");
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加 ")], 1)], 2), _c("a-form-model-item", {
    attrs: {
      label: "关联介绍视频",
      prop: "relationvideos",
      rules: {
        required: false,
        message: "请添加关联介绍视频"
      }
    }
  }, [_vm._l(_vm.info.relationvideos || [], function (it, index) {
    return [_c("div", {
      key: index,
      staticClass: "video-list"
    }, [_c("img", {
      staticClass: "video-list__img",
      attrs: {
        src: it.cover
      }
    }), _c("span", {
      staticClass: "nft-list__item"
    }, [_vm._v(_vm._s(it.name))]), _c("span", {
      staticClass: "nft-list__icon",
      on: {
        click: function click($event) {
          return _vm.handleVideo("edit", index);
        }
      }
    }, [_vm._v("编辑")]), !_vm.isView ? _c("span", {
      staticClass: "nft-list__icon",
      on: {
        click: function click($event) {
          return _vm.handleVideo("del", index);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()])];
  }), _c("a-button", {
    attrs: {
      disabled: _vm.isView
    },
    on: {
      click: function click($event) {
        return _vm.handleVideo("add");
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加 ")], 1)], 2), _c("br"), _c("br"), _c("a-form-model-item", {
    attrs: {
      label: "网站链接",
      prop: "websiteurl",
      rules: {
        required: true,
        type: "url",
        message: "请输入正确的网站链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.websiteurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "websiteurl", $$v);
      },
      expression: "info.websiteurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "iOS App Store链接",
      prop: "iosstoreurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的AppStore链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.iosstoreurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "iosstoreurl", $$v);
      },
      expression: "info.iosstoreurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "iOS TF链接",
      prop: "iostfurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的iOS TF链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.iostfurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "iostfurl", $$v);
      },
      expression: "info.iostfurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Google Play链接",
      prop: "googleplayurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的Google Play链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.googleplayurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "googleplayurl", $$v);
      },
      expression: "info.googleplayurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Android APK链接",
      prop: "androidapkurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的Android APK链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.androidapkurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "androidapkurl", $$v);
      },
      expression: "info.androidapkurl"
    }
  })], 1), _c("br"), _c("br"), _c("a-form-model-item", {
    attrs: {
      label: "Discord链接",
      prop: "discordurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的Discord链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.discordurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "discordurl", $$v);
      },
      expression: "info.discordurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Twitter链接",
      prop: "twitterurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的Twitter链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.twitterurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "twitterurl", $$v);
      },
      expression: "info.twitterurl"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "Telegram链接",
      prop: "telegramurl",
      rules: {
        required: false,
        pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
        message: "请输入正确的Telegram链接"
      }
    }
  }, [_c("a-input", {
    attrs: {
      autocomplete: "off",
      size: "large",
      disabled: _vm.isView,
      placeholder: ""
    },
    model: {
      value: _vm.info.telegramurl,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "telegramurl", $$v);
      },
      expression: "info.telegramurl"
    }
  })], 1), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    staticClass: "backbutton",
    staticStyle: {
      width: "90px",
      height: "40px",
      "margin-right": "20px",
      "border-radius": "6px",
      border: "0",
      background: "#f5f5f5"
    },
    on: {
      click: function click($event) {
        return _vm.handleBack();
      }
    }
  }, [_vm._v("返回")]), !_vm.isView ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["launchpad:btn:submit"],
      expression: "['launchpad:btn:submit']"
    }],
    staticStyle: {
      width: "90px",
      height: "40px",
      background: "#3270f5",
      "border-radius": "6px",
      color: "#ffffff",
      border: "0"
    },
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("Video", {
    attrs: {
      visible: _vm.addVisible,
      source: _vm.source,
      index: _vm.tabIndex,
      disabled: _vm.isView
    },
    on: {
      handleAddCancle: _vm.handleAddCancle,
      handleAddSubmit: _vm.handleAddSubmitSuccess
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };