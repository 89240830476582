import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DPopup", {
    attrs: {
      title: _vm.title,
      comVisible: _vm.visible,
      modalWidth: "800px"
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("div", {
          staticClass: "constainer"
        }, [_c("div", {
          staticClass: "constainer-header"
        }, [_c("a-form-model", {
          ref: "videoForm",
          staticClass: "nft-form",
          attrs: {
            labelCol: {
              style: "width: 85px"
            },
            model: _vm.item
          }
        }, [_c("section", {
          staticClass: "left"
        }, [_c("a-form-model-item", {
          attrs: {
            label: "视频标题",
            rules: {
              required: true,
              message: "请输入正确的视频标题"
            },
            prop: "name"
          }
        }, [_c("a-input", {
          attrs: {
            autoComplete: "off",
            size: "large",
            placeholder: "请输入",
            maxLength: 200,
            disabled: _vm.disabled
          },
          model: {
            value: _vm.item.name,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c("a-form-model-item", {
          attrs: {
            label: "视频链接",
            rules: {
              required: true,
              message: "请输入正确的视频链接"
            },
            prop: "link"
          }
        }, [_c("a-input", {
          attrs: {
            autoComplete: "off",
            size: "large",
            placeholder: "请输入",
            disabled: _vm.disabled
          },
          model: {
            value: _vm.item.link,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "link", $$v);
            },
            expression: "item.link"
          }
        })], 1), _c("a-form-model-item", {
          attrs: {
            label: "视频封面",
            rules: {
              required: true,
              message: "请选择视频封面"
            },
            prop: "cover"
          }
        }, [_c("UploadImg", {
          staticStyle: {
            width: "200px"
          },
          attrs: {
            showTips: false,
            listType: "picture-card",
            max: 1,
            imgProportion: [1280, 400],
            memorySize: "10240kb",
            disabled: _vm.disabled,
            force: false,
            equalSides: false,
            from: "dctool"
          },
          on: {
            previewImg: _vm.preview,
            change: _vm.handleNftFileChanged
          },
          model: {
            value: _vm.item.cover,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "cover", $$v);
            },
            expression: "item.cover"
          }
        })], 1)], 1)]), _c("a-form-model-item", {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          attrs: {
            size: "large",
            disabled: _vm.loading
          },
          on: {
            click: _vm.cancel
          }
        }, [_vm._v(_vm._s("取消"))]), !_vm.disabled ? _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "large",
            type: "primary",
            loading: _vm.loading
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("保存")]) : _vm._e()], 1)], 1), _c("PreviewPic", {
          attrs: {
            isShowPic: _vm.mediaType == "img" && _vm.isShowMedia,
            previewImage: _vm.mediaSource
          },
          on: {
            closePreviewpic: _vm.previewHandleCancel
          }
        }), _c("PreviewMedia", {
          attrs: {
            isShow: _vm.mediaType != "img" && _vm.isShowMedia,
            previewSource: _vm.mediaSource,
            mediaType: _vm.mediaType,
            modalWidth: _vm.mediaType == "audio" ? "380px" : "600px"
          },
          on: {
            closePreview: _vm.previewHandleCancel
          }
        })], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };