import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import { deepClone } from "@/utils/help";
import PreviewMedia from "@/components/PreviewMedia";
export default {
  name: "launchpadNft",
  components: {
    PreviewMedia: PreviewMedia
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    source: {
      // 传入的资源数据
      type: Object,
      default: function _default() {}
    },
    index: {
      type: Number,
      default: -1 // -1的时候是新增
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) {
        if (this.source && Object.keys(this.source).length > 0) {
          this.item = deepClone(this.source);
        } else {
          this.item = {};
        }
      }
    }
  },
  computed: {
    title: function title() {
      if (this.item.id) {
        return "编辑视频";
      } else {
        return "添加视频";
      }
    }
  },
  data: function data() {
    return {
      editId: "",
      loading: false,
      item: {},
      type: "",
      mediaType: "img",
      mediaSource: "",
      isShowMedia: false
    };
  },
  methods: {
    cancel: function cancel() {
      this.$emit("handleAddCancle");
    },
    submit: function submit() {
      var _this = this;
      this.$refs.videoForm.validate(function (valid) {
        if (valid) {
          // 数据需要整合下
          // 提交的levels和stats内部的字段需要变更下--对应的id字段该删除还是要删除
          var data = deepClone(_this.item);
          _this.$emit("handleAddSubmit", _this.index, data);
        }
      });
    },
    handleValidate: function handleValidate(field) {
      this.$refs.videoForm.validateField(field);
    },
    handleNftFileChanged: function handleNftFileChanged(url) {
      // 全部先当作一个来处理呗
      this.$set(this.item, "cover", url);
      this.handleValidate("cover");
    },
    preview: function preview(url) {
      this.mediaType = "img";
      // 根据类型来判断下呗
      this.mediaSource = url;
      this.isShowMedia = true;
      // console.log(this.mediaType, this.mediaSource, this.isShowMedia)
    },
    previewHandleCancel: function previewHandleCancel() {
      this.isShowMedia = false;
    }
  }
};